import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PaginationContextProps, PublicationProps } from '../../types/pages'
import { CustomerStoriesCategory, CustomerStoriesLandingProps, CustomerStoryPreview } from '../../types/customerStories'
import { SectionBase } from '../../types/sections'
// Components
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import { CustomerStoriesLandingHero, StoriesHeaderWithCategories } from '../../components/customerStories'
import Section from '../../components/sections'
import { CustomerStoriesPaginatedStories } from '../../components/customerStories/commons'

interface DataQuery {
  customerStoriesLanding: {
    pageInfo: PageInfoProps
    locale: string
    meta: PublicationProps
  } & CustomerStoriesLandingProps
  categoryList: {
    nodes: CustomerStoriesCategory[]
  }
  customerStoriesList: {
    nodes: CustomerStoryPreview[]
  }
}

type PageContext = PaginationContextProps

const CustomerStories: React.FC<PageProps<DataQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const {
    customerStoriesLanding: {
      pageInfo,
      locale,
      meta,
      headerBackgroundColor,
      headerCustomerStories,
      headerHeadline,
      sections,
      storiesListLabel,
      allStoriesLabel,
      paginationPreviousLabel,
      paginationNextLabel,
    },
    categoryList: { nodes: categories },
    customerStoriesList: { nodes: stories },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />
      {currentPage == 1 && headerHeadline && (
        <Box mb={6}>
          <CustomerStoriesLandingHero
            headline={headerHeadline}
            bgColor={headerBackgroundColor.hex}
            customerStories={headerCustomerStories}
          />
        </Box>
      )}
      {currentPage === 1 &&
        sections.map((s: SectionBase) => s?.model && <Section key={s.id} sectionApiKey={s.model.apiKey} data={s} />)}

      {stories.length > 0 && (
        <Box as={'section'}>
          <StoriesHeaderWithCategories
            title={storiesListLabel}
            customerStoriesLandingPageInfo={pageInfo}
            allLabel={allStoriesLabel}
            categories={categories}
            isAll={true}
          />
          <CustomerStoriesPaginatedStories
            stories={stories}
            prevLabel={paginationPreviousLabel}
            previousPagePath={previousPagePath}
            nextLabel={paginationNextLabel}
            nextPagePath={nextPagePath}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </Box>
      )}
    </Layout>
  )
}

export const query = graphql`
  query CustomerStoriesQuery($skip: Int!, $limit: Int!) {
    customerStoriesLanding: datoCmsCustomerStoriesLanding {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      storiesListLabel
      headerHeadline
      headerBackgroundColor {
        hex
      }
      headerCustomerStories {
        ...customerStoryPreviewWithCategoriesFragment
      }
      sections {
        ...sectionTestimonialFragment
        ...sectionLogoWallFragment
      }
      storiesListLabel
      allStoriesLabel
      paginationPreviousLabel
      paginationNextLabel
    }
    categoryList: allDatoCmsCustomerStoriesCategory(sort: { fields: name }) {
      nodes {
        ...customerStoriesCategoryFragment
      }
    }
    customerStoriesList: allDatoCmsCustomerStory(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        ...customerStoryPreviewWithCategoriesFragment
      }
    }
  }
`

export default CustomerStories
